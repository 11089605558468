import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/handgemacht_logo.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTshirt, faCut, faUser, fas } from '@fortawesome/free-solid-svg-icons'
import { fab, faInstagram, faEtsy } from '@fortawesome/free-brands-svg-icons'

library.add(fab, faInstagram, faEtsy, fas, faCut, faTshirt, faUser)


const NavbarBurger = props => (
  <button
    onClick={props.toggleMenu}
    className={`button navbar-burger ${props.active ? 'is-active' : ''}`}
  >
    <span />
    <span />
    <span />
  </button>
);

class Header extends React.Component {
  state = {
    activeMenu: false,
  };
  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
    });
  };
  render() {
    let { siteTitle } = this.props;

    return (
      <nav className="navbar has-shadow" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img src={logo} alt="logo" />
          </Link>
          <NavbarBurger
            active={this.state.activeMenu}
            toggleMenu={this.toggleMenu}
          />

        </div>

        <div id="navbarBasicExample" className={`navbar-menu ${this.state.activeMenu ? 'is-active' : ''}`}>
          <div className="navbar-start">
            <Link to="/kurse/" className="navbar-item is-capitalized">
              <span className="icon"><FontAwesomeIcon icon={faCut} /></span><span></span> Nähkurse
            </Link>
            <Link to="/werke/" className="navbar-item is-capitalized">
              <span className="icon"><FontAwesomeIcon icon={faTshirt} /></span><span></span> Hand-K-Machtes
            </Link>
            <Link to="/kontakt/" className="navbar-item is-capitalized" state={{ typeRequest: "allgemein" }}>
              <span className="icon"><FontAwesomeIcon icon={faUser} /></span><span></span> Kontakt
            </Link>
          </div>
          <div class="navbar-end">
            <a class="navbar-item" href="https://www.instagram.com/hand_k_macht/">
              <span className="icon"><FontAwesomeIcon icon={faInstagram} size="2x" /></span>
            </a>
          </div>
        </div>
      </nav>
    );
  }
}


Header.propTypes = {
  siteTitle: PropTypes.string,
  siteTitle2: PropTypes.string,
  pages: PropTypes.array
}

Header.defaultProps = {
  siteTitle: ``,
  siteTitle2: `Luli`,
  pages: ['Home', 'Page1']
}

export default Header
