import React from "react"

import { Link } from "gatsby"

const Footer = () => {
    return (
        <footer class="footer">
            <section className="section footer-section">
                <div className="container">
                    <div className="columns">
                        <div className="column is-5">
                            <h6 className="footer_title">Über Hand-K-Macht</h6>
                            <p>Bei Hand-K-Macht lernen Anfänger und Fortgeschrittene in Ingolstadt und Neuburg
                                 das Nähen. Außerdem können bei mir handgemachte Unikate bestellt werden. Lass 
                                 dich in meinem Hand-K-Macht Bereich inspirieren. Viel Spaß beim Stöbern.</p>
                        </div>
                        <div className="column is-3 is-offset-1">
                            <h6 className="footer_title">Inhalt</h6>
                            <ul className="footer_list">
                                <li>
                                    <Link to="/kurse/" className="">Nähkurse</Link>
                                </li>
                                <li>
                                    <Link to="/werke/" className="">Hand-K-Machtes</Link>
                                </li>
                                <li>
                                    <Link to="/kontakt/" className="" state={{ typeRequest: "allgemein" }}>Kontakt</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="column is-3"></div>
                    </div>
                    <div class="sub-footer is-centered">
                        <p><Link to="/impressum/" className="">Impressum</Link></p>
                        <p><Link to="/agb/" className="">AGB</Link></p>
                    </div>
                </div>
            </section>
        </footer>
    )
}

export default Footer